/* Custom Styles Added by Aaron Egan */

.toolbar__content {
  justify-content: center !important;
}
.free-shipping-banner__content {
  font-size: 18px !important;
}

/* Angle 3D New Classes - AE 230522 */
  
  .tda-customizer-footer {
    display: none !important;
  }
  .tda-product-details-top-container {
     display: none !important;
  }
.tda-customizer-controls-container h2 {
  display: none !important;
}
.tda-customizer-app-section-wrapper h1 {
  text-align: left;
  color: #456c6b;
}









/* ------------------------------------------------------------ */
/* 3D model app */
/* ------------------------------------------------------------ */
 .tda-customizer-controls-container h2 { display: none; }

.page-width { 
  padding-left: 0;
  padding-right: 0;
}


.tda-customizer-product-title {
  display: none;
  visibility: hidden;
}

 .tda-customizer-variant-price-container,
 .tda-customizer-variant-compare-at-price-container,
 .tda-customizer-variant-price-sale {
  all: unset;
}

 .tda-customizer-variant-price-container * {
  font-size: inherit;
  line-height: inherit;
}

 .tda-customizer-variant-price-container,
 .tda-customizer-variant-compare-at-price-container {
  display: flex !important;
  justify-content: center;
  align-items: baseline;
}

 .tda-customizer-variant-price-container > div {
  display: flex;
  flex-direction: row;
  flex: 0;
  width: auto;
}

  .tda-customizer-variant-price {
  margin-left: 0.75ch;
  margin-right: 0.75ch;
  color: var(--color_secondary) !important;
}

 .tda-customizer-variant-compare-at-price,
 .tda-customizer-variant-price-sale {
  margin-left: 0;
  margin-right: 0.75ch; 
  color: var(--color_secondary) !important;
}

  .tda-customizer-variant-price,
 .tda-customizer-variant-compare-at-price {
  display: block;
}
  .tda-customizer-variant-price-container .tda-customizer-variant-price::first-letter,
  .tda-customizer-variant-price-container .tda-customizer-variant-compare-at-price::first-letter {
  display: none;
  visibility: hidden;
  font-size: 1px;
}

 .tda-customizer-variant-price-sale {
  font-size: inherit;
  line-height: inherit;
  color: var(--color_highlight-orange) !important;
  text-transform: lowercase;
  text-transform: capitalize;
}

 .tda-app-section-content {
  margin-top: 0;
  clear: both;
}


 .tda-customizer-app-section-wrapper > div > h1 {
  display: none;
  visibility: hidden;
}

 .tda-customizer-controls-container {
  border-style: none !important;
}

 .tda-customizer-footer {
  background-color: inherit !important;
  border-top-style: none;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}


@media only screen and (min-width: 1150px), only screen and (orientation: landscape) {
    .tda-customizer-footer-right {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (min-width: 1150px), only screen and (orientation: landscape) {
   .tda-customizer-footer-left {
      max-width: 60% !important;
      padding-right: 0 !important;
  }
}

@media only screen and (min-width: 1150px), only screen and (orientation: landscape) {
   .tda-customizer-customization-option {
      margin-bottom: 0 !important;
  }
}

 .tda-customizer-controls-container,
 .tda-customizer-app-section-container {
  padding: 0 !important;
}

 .tda-customizer-app-section-container,
 .tda-app-section-model-viewer-sticky-container {
  background: unset !important
}

 .tda-app-section-model-viewer-container {
  margin-top: 0;
}

 .tda-app-section-model-viewer-sticky-container {
  margin-top: 0 !important;
}

.cart-recommendations .grid-product__title {
    font-size: 16px;
}
.cart-recommendations .grid-item__meta {
  margin: 20px 5px;
}
.cart-recommendations .stamped-product-reviews-badge {
  display: none !important;
}


 .tda-customizer-customization-option,
 .tda-accordion-panel {
  margin: 0 0 8px !important;
}

 .tda-customizer-customization-option,
 .tda-accordion-panel,
 .tda-accordion,
 .tda-accordion-active {
  background-color: #FFF !important;
  border-style: none !important;
}

 .tda-accordion {
  font-size: calc(var(--typeHeaderSize)*.55555556) !important;
  line-height: 1.3 !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-radius: 0 !important;
  color: var(--color_secondary) !important;
}

 .tda-accordion::after {
  color: var(--color_secondary) !important;
}

 .tda-customizer-customization-option-title {
  color: var(--color_secondary) !important;
}


 .tda-customizer-customization-option,
 .tda-accordion,
 .tda-accordion-active + .tda-accordion-panel {
  padding: 12px 20px !important;
  box-sizing: border-box;
}

 .tda-accordion-panel .tda-customizer-customization-option {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

 .tda-customizer-customization-option-value-list:not(.tda-customizer-color-palette-customization-option-value-list) {
  flex-direction: column !important;
  margin-top: 0 !important;

}

 .tda-customizer-color-palette-customization-option-value-list {
  margin-top: 36px !important;
}

 .tda-tooltip .tda-tooltiptext {
  background-color: var(--color_primary) !important;
  border-color: var(--color_primary) !important;
  color: #FFF !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

 .tda-tooltip .tda-tooltiptext::after {
  border-top-color: var(--color_primary) !important;
}


 .tda-customizer-customization-option-value-list:not(.tda-customizer-color-palette-customization-option-value-list) .tda-customizer-customization-option-value  {
  border-style: none !important;
  background: var(--color_light-3) !important;
  color: var(--color_primary) !important;
}

 .tda-customizer-customization-option-value-list:not(.tda-customizer-color-palette-customization-option-value-list) .tda-customizer-customization-option-value:hover  {
  border-style: none !important;
  background: var(--color_secondary) !important;
  color: var(--color_light-3) !important;
}

 .tda-customizer-customization-option-value-list:not(.tda-customizer-color-palette-customization-option-value-list) .tda-customizer-customization-option-value-selected {
  border-style: none !important;
  background: var(--color_primary) !important;
  color: #FFF !important;
}

 .tda-customizer-customization-option-value:not(:last-child) {
  margin-bottom: 8px !important;   
}

 .tda-customizer-customization-option-value-title {
  padding: 8px 16px;
  text-transform: none !important;
  font-size: 1.0em;
  line-height: 130%;
  font-weight: 700;
}

 .tda-customizer-customization-option + .tda-customizer-customization-option {
  margin-top: 24px !important;
}

 .tda-accordion-panel .tda-customizer-customization-option h3,
 .tda-customizer-customization-option h3 {
    font-size:calc(var(--typeHeaderSize)*0.44444444) !important;
    line-height: 1.0 !important;
    font-weight: 700 !important;
    margin-bottom: 8px !important; 
}

@media only screen and (min-width:769px){
   .tda-accordion-panel .tda-customizer-customization-option h3,
   .tda-customizer-customization-option h3 {
    font-size:calc(var(--typeHeaderSize)*0.55555556) !important;
    line-height: 1.3 !important;
  }
}

 .tda-customizer-customization-option h3:empty {
  display: none;
  visibility: hidden;
}

 .tda-customizer-controls-container {
  height: unset !important;
  overflow: visible !important;
}

#shopify-section-template--15479078682819__165328536243fecb0a {
  padding-top: 50px;
  background: #fff;
}

.tda-accordion {
  background: #edf1f4 !important;
}

.tda-customizer-customization-options-container {
  padding-left: 15%;
  padding-top: 30px;
}